import { Link, useParams } from "react-router-dom";
import c from "classnames";
import Txs from "../Txs/Txs2";
import Loading from "../../components/Loading";
import ProposerFinder from "../../components/ProposerFinder";
// import { useCurrentChain } from "../../contexts/ChainsContext";
import { fromISOTime } from "../../scripts/utility";
import NotFound from "../../components/NotFound";
import WithFetch from "../../HOCs/WithFetch";
import s from "./Block.module.scss";

const heightButton = (height: number) => (
  <span className={s.height}>
    <span>{height}</span>
    <Link to={`../blocks/${Number(height) - 1}`}>
      <i className="material-icons">chevron_left</i>
    </Link>
    <Link to={`../blocks/${Number(height) + 1}`}>
      <i className="material-icons">chevron_right</i>
    </Link>
  </span>
);

const Block = () => {
  const { height } = useParams();

  return (
    <WithFetch
      url={`/cosmos/base/tendermint/v1beta1/blocks/${height}`}
      lcd
      loading={<Loading />}
    >
      {blockData => {
        return blockData ? (
          <>
            <h2 className="title">
              Block<span>#{height}</span>
            </h2>
            <div className={c(s.list, s.blockInfo)}>
              <div className={s.row}>
                <div className={s.head}>Chain ID</div>
                <div className={s.body}>{blockData.block.header.chain_id}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Block height</div>
                <div className={s.body}>{heightButton(blockData.block.header.height)}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Timestamp</div>
                <div className={s.body}>{fromISOTime(blockData.block.header.time)}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Transactions</div>
                <div className={s.body}>{blockData.block.data.txs.length}</div>
              </div>
              <div className={s.row}>
                <div className={s.head}>Proposer</div>
                <div className={s.body}>
                  <ProposerFinder
                    q={"validator"}
                    v={blockData.block.header.proposer_address}
                  />
                </div>
              </div>
            </div>

            <Txs txs={blockData.block.data.txs} />
          </>
        ) : (
          <NotFound keyword={height} />
        )
      }}
    </WithFetch>
  );
};

export default Block;
