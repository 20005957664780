import { useParams } from "react-router-dom";
import { createContext } from "./createContext";

export const getChains = () =>
  fetch("https://assets.xpla.io/chains.json")
    .then(res => res.json())
    .then((data: Record<string, ChainOption>) => {
      const chains = {
        ...data,
        hypercube: {
          name: 'hypercube',
          chainID: 'hypercube_270-2',
          lcd: 'https://hypercube-lcd.xpla.io',
          api: 'https://hypercube-api.xpla.io',
          walletconnectID: 2,
        },
        localxpla: {
          name: 'localxpla',
          chainID: 'localxpla',
          lcd: 'http://localhost:1317',
          mantle: 'http://localhost:1337',
        }
      };
      
      return Object.values(chains);
    });

export const [useChains, ChainsProvider] =
  createContext<ChainOption[]>("Chains");

const useNetworkFromRouteMatch = () => {
  const { network } = useParams();
  return network;
};

export const useCurrentChain = () => {
  const chains = useChains();
  const network = useNetworkFromRouteMatch();

  const chain =
    chains.find(chain => chain.name === network || chain.chainID === network) ??
    chains.find(chain => chain.name === "mainnet"); // return mainnet for default chain

  if (!chain) {
    throw new Error("Chain is not defined");
  }

  return chain;
};

export const useFCDURL = () => {
  const { lcd } = useCurrentChain();
  return lcd;
};

export const useIsClassic = () => {
  const { chainID } = useCurrentChain();
  return chainID.startsWith("columbus");
};
