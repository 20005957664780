import React from "react";
import { Link } from "react-router-dom";
import c from "classnames";
import { useCurrentChain } from "../contexts/ChainsContext";
import useRequest from "../hooks/useRequest";

type Props = {
  q: string;
  v?: string;
  className?: string;
  brand?: boolean;
  network?: string;
};

const Finder = ({ q, v, className, brand, network }: Props) => {
  const { name, api } = useCurrentChain();
  const url = `${api}/v1/validators?address=${v ? encodeURIComponent(v) : v}`
  const { data, isLoading } = useRequest<any>({ url });

  return isLoading ? <></> : (
    <Link
      to={`/${name}/${q}/${data.operator_address}`}
      className={c(className, brand && "text-primary")}
      rel="noopener noreferrer"
    >
      {data.description.moniker}
    </Link>
  );
};

export default Finder;
